import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CampaignIcon from "@mui/icons-material/Campaign";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Collapse from "@mui/material/Collapse";
import { useNavigate, useLocation } from "react-router-dom";
import LogoRed from "../../../images/red_C_cyreen_logo.png";
// import Topbar from "./Topbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  AccountCircle,
  Insights,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Language,
  Logout,
  ManageAccounts,
  MoreVert,
  Settings,
} from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, IconButton, Paper, Popover, Toolbar, Typography } from "@mui/material";
import { StyledDrawer, DrawerHeader } from "./NavStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { allowedNavOptions } from "../../../api_calls/userManagement";
import { logout } from "../../../api_calls/campaignRequests";
import BottomNav from "./BottomNav";
import { StyledLightRedInformationTypography } from "../StyledComponents/styled";

const LogoImage = styled("img")({
  maxWidth: "100%",
  height: "auto",
  padding: "10px",
});

function create_nested_list(nestedItems, navigate, location) {
  return (
    <List>
      {nestedItems.map((nestedItem) => {
        return (
          <ListItem
            key={nestedItem.path}
            disablePadding
            onClick={(e) => {
              navigate(nestedItem.path);
            }}
          >
            <ListItemButton component="div" key={nestedItem.text} sx={{ pl: 4 }}>
              <ListItemIcon sx={{ minWidth: 24 }}>
                <span
                  className={
                    location.pathname === nestedItem.path
                      ? "nestedBulletedList nestedBulletedListSelected"
                      : "nestedBulletedList"
                  }
                ></span>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component={"span"}
                    sx={
                      location.pathname === nestedItem.path
                        ? { fontSize: "0.8rem", fontWeight: "bold" }
                        : { fontSize: "0.8rem" }
                    }
                  >
                    {nestedItem.text}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default function SideNav() {
  const userStateData = useSelector((state) => state.userSelections);
  const sidebarOpenState = typeof userStateData.open_sidebar !== "undefined" ? userStateData.open_sidebar : true;
  const [open, setOpen] = React.useState(sidebarOpenState);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatchAction = useDispatch();

  const [navItems, setNavItems] = React.useState([]);

  const [openNestedList, setOpenNestedList] = React.useState({});
  // popover for nested items
  const [anchorEl, setAnchorEl] = React.useState(null);
  //max height for side nav items.
  const maxContainerHeight = `${window.innerHeight-286}px`;

  const handleParentNavClick = (event, item) => {
    if (item.nested_list) {
      if (open) {
        toggleNestedList(item.nested_keyword);
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else {
      navigate(item.path);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const toggleNestedList = (keyword) => {
    setOpenNestedList((prevOpenNestedList) => ({
      ...prevOpenNestedList,
      [keyword]: {
        open: !prevOpenNestedList[keyword].open,
      },
    }));
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatchAction(
      updateStateData({
        open_sidebar: !open,
      })
    );
  };

  React.useEffect(() => {
    
    // build nav bar (based on user permissions)
    allowedNavOptions(location).then((info) => {
      // console.log("Info:", info);
      if (info.success === true) {
        const initialNestedStates = {};
        info.navItems.forEach((item) => {
          if (item.nested_list) {
            initialNestedStates[item.nested_keyword] = {
              open: item.active,
            };
          }
        });
        setNavItems(info.navItems);
        setOpenNestedList(initialNestedStates);
      }
    });
  }, []);

  return (
    <>
      <Box component="div" sx={{ pb: 7 }}>
        <Toolbar sx={{ width: open ? 248 : 52, position: "fixed", zIndex: "1202" }}>
          {/* --------- toggle icon --------- */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            sx={{ marginLeft: "auto", backgroundColor: "#fff" }}
            onClick={handleDrawerOpen}
          >
            {open ? (
              <ChevronLeftIcon
                sx={{
                  border: "1px dotted #62626E",
                  borderRadius: "50%",
                  color: "#62626E",
                }}
                fontSize="small"
              />
            ) : (
              <ChevronRightIcon
                sx={{
                  border: "1px dotted #62626E",
                  borderRadius: "50%",
                  color: "#62626E",
                }}
                fontSize="small"
              />
            )}
          </IconButton>
        </Toolbar>
        <StyledDrawer variant="permanent" open={open}>
          <DrawerHeader>
            <LogoImage src={LogoRed} alt="Logo" width={open ? "80" : "40"} />
          </DrawerHeader>

          <StyledLightRedInformationTypography sx={{ justifyContent: open ? "center" : "center" }} variant="body2">
            1.0.170
          </StyledLightRedInformationTypography>

          <div style={open ? { maxHeight: maxContainerHeight, overflowY: "auto" } : {}}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List component="div">
                {navItems.map((item) => (
                  <div key={item.path}>
                    <ListItem
                      sx={{ display: "block" }}
                      id={item.nested_keyword}
                      onClick={(e) => handleParentNavClick(e, item)}
                    >
                      <ListItemButton
                        className={item.active ? "sideNavItemSelected" : "sideNavItem"}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          borderRadius: "10px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: item.active ? "rgba(240, 75, 85, 0.89)" : "#62626E",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                        {/* ----- drop down arrow for nested list ----- */}
                        {item.nested_list && open && (
                          <>
                            {typeof openNestedList[item.nested_keyword] !== "undefined" &&
                            openNestedList[item.nested_keyword].open ? (
                              <KeyboardArrowDown
                                onClick={() => {
                                  toggleNestedList(item.nested_keyword);
                                }}
                              />
                            ) : (
                              <KeyboardArrowRight
                                onClick={() => {
                                  toggleNestedList(item.nested_keyword);
                                }}
                              />
                            )}
                          </>
                        )}
                      </ListItemButton>
                      {/* ------- nested list ---------- */}
                      {item.nested_list === true &&
                        typeof openNestedList[item.nested_keyword] !== "undefined" &&
                        openNestedList[item.nested_keyword].open && (
                          <>{open && create_nested_list(item.nested_items, navigate, location)}</>
                        )}
                    </ListItem>
                  </div>
                ))}
              </List>
            </Collapse>
          </div>

          {/* ----------- bottom navigation ------------ */}
          <BottomNav open={open} />
        </StyledDrawer>
      </Box>
      {/* -------- popover for nested items --------- */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {anchorEl &&
          navItems.map(
            (item) =>
              item.nested_list &&
              item.nested_keyword === anchorEl.id &&
              create_nested_list(item.nested_items, navigate, location)
          )}
      </Popover>
    </>
  );
}
