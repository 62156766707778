import React from "react";
import {
  Box,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import {
  StyledCheckbox,
  StyledDarkRedInformationTypography,
  StyledInfoBox,
  StyledInformationSpan,
  StyledMainBox,
} from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import DateInputField from "../../../components/commonComponents/TextInputs/DateInputs";
import { Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { checkUserRole, fetchCompanies } from "../../../api_calls/userManagement";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { saveCampaignsInformation } from "../../../api_calls/campaignRequests";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import { checkTodayDate, formatToStandardDate, standardFormatToday } from "../../../api_calls/utils";
import moment from "moment-timezone";

const AddNewCampaign = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [showManualBrandCompany, setShowManualBrandCompany] = React.useState(false);
  const [showStartDateConfirmation, setStartDateConfirmation] = React.useState(false);
  const [showEndDateConfirmation, setStartEndConfirmation] = React.useState(false);

  const checkRole = checkUserRole();
  const handleFieldChange = (campaignData) => {
    const selectedCampaignData =
      typeof currentStateData.new_campaign_data !== "undefined" ? currentStateData.new_campaign_data : {};

    dispatchAction(
      updateStateData({
        new_campaign_data: { ...selectedCampaignData, ...campaignData },
      })
    );
  };

  const setCampaignStartDateConstraint = () => {
    return standardFormatToday();
    // const today = standardFormatToday();
    // const nextDay = new Date(today);
    // nextDay.setDate(today.getDate() + 1);
    // return nextDay;
  };

  React.useEffect(() => {
    fetchCompanies();
    if (typeof campaignRecord !== "undefined" && Object.keys(campaignRecord).length !== 0) {
      dispatchAction(
        updateStateData({
          new_campaign_data: {
            campaign_id: campaignRecord.campaign_id,
            campaign_name: campaignRecord.campaign_name,
            brand_name: campaignRecord.brand,
            campaign_start_date: formatToStandardDate(campaignRecord.campaign_start_date),
            campaign_end_date: formatToStandardDate(campaignRecord.campaign_end_date),
            campaign_retailer_company: campaignRecord.retailer_company_name,
            campaign_brand_company: campaignRecord.brand_company_name,
            campaign_retailer_company_id: campaignRecord.retailer_company_id,
            campaign_brand_company_id: campaignRecord.brand_company_id,
            report_status: campaignRecord.campaign_status_code === 4 ? true : false,
            campaign_report_status_code: campaignRecord.campaign_status_code,
            start_status: campaignRecord.campaign_start_status,
            end_status: campaignRecord.campaign_end_status,
          },
        })
      );
    }
  }, []);
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {usedFor === "update" ? "Update Campaign" : "Add New Campaign"}</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {/* -------- campaign and brand name ------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------- campaign name -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="campaignName"
                label="Campaign Name"
                fieldName="campaign_name"
                showlabel={true}
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.campaign_name !== "undefined"
                    ? currentStateData.new_campaign_data.campaign_name
                    : ""
                }
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    campaign_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldErrors.campaign_name !== "undefined" && fieldErrors.campaign_name !== ""
                    ? fieldErrors.campaign_name
                    : ""
                }
              />
              {/* ------ brand name -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="brandName"
                label="Brand Name"
                fieldName="brand_name"
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.brand_name !== "undefined"
                    ? currentStateData.new_campaign_data.brand_name
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    brand_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldErrors.brand_name !== "undefined" && fieldErrors.brand_name !== ""
                    ? fieldErrors.brand_name
                    : ""
                }
              />
            </Stack>
            <Box height={40} />
            {/* -------- campaign start/end date ----------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------ campaign start date -------- */}
              <DateInputField
                fieldRequired={true}
                elemId="startDate"
                label="Start Date*"
                fieldName="start_date"
                className="start_date_class"
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.campaign_start_date !== "undefined"
                    ? currentStateData.new_campaign_data.campaign_start_date
                    : ""
                }
                showlabel={false}
                inputType={"date"}
                disabled={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.start_status !== "undefined" &&
                  ["started_today", "started_days_ago"].includes(currentStateData.new_campaign_data.start_status)
                    ? true
                    : false
                }
                minSelectable={setCampaignStartDateConstraint()}
                // enabledDates={(date) => date.getDay() === 1}
                onChangeCall={(selectedDate) => {
                  if (usedFor === "update") {
                    setStartDateConfirmation(true);
                  }
                  handleFieldChange({
                    campaign_start_date: selectedDate,
                  });
                }}
                fieldError={
                  typeof fieldErrors.start_date !== "undefined" && fieldErrors.start_date !== ""
                    ? fieldErrors.start_date
                    : ""
                }
              />
              {/* -------- campaign end date ------- */}
              <DateInputField
                fieldRequired={true}
                elemId="startDate"
                label="End Date*"
                fieldName="end_date"
                className="end_date_class"
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.campaign_end_date !== "undefined"
                    ? currentStateData.new_campaign_data.campaign_end_date
                    : ""
                }
                showlabel={false}
                inputType={"date"}
                minSelectable={(() => {
                  if (
                    typeof currentStateData.new_campaign_data !== "undefined" &&
                    typeof currentStateData.new_campaign_data.campaign_start_date !== "undefined" &&
                    moment(currentStateData.new_campaign_data.campaign_start_date).isSameOrAfter(moment(), "day")
                  ) {
                    return currentStateData.new_campaign_data.campaign_start_date;
                  } else {
                    return setCampaignStartDateConstraint();
                  }
                })()}
                // enabledDates={(date) => date.getDay() === 0}
                disabled={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.end_status !== "undefined" &&
                  ["ended_days_ago"].includes(currentStateData.new_campaign_data.end_status)
                    ? true
                    : false
                }
                onChangeCall={(selectedDate) => {
                  if (usedFor === "update") {
                    setStartEndConfirmation(true);
                  } else {
                    setStartEndConfirmation(false);
                  }
                  handleFieldChange({
                    campaign_end_date: selectedDate,
                  });
                }}
                fieldError={
                  typeof fieldErrors.end_date !== "undefined" && fieldErrors.end_date !== "" ? fieldErrors.end_date : ""
                }
              />
            </Stack>
            {(showStartDateConfirmation || showEndDateConfirmation) && (
              <>
                <Box height={40} />
                <StyledInfoBox>
                  <Typography variant="body2">
                    When adjusting the dates of an existing campaign, please note that the dates for associated clips are
                    not automatically updated. Remember to manually adjust the dates of relevant clips, if needed.
                  </Typography>
                </StyledInfoBox>
              </>
            )}

            <Box height={40} />
            {/* -------- retailer/brand company --------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------- retailer company ------- */}
              {/* <SingleSelect
                label="Retailer Company*"
                enableFullWidth={true}
                options={typeof currentStateData.retailers_list !== "undefined" ? currentStateData.retailers_list : []}
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.campaign_retailer_company !== "undefined"
                    ? {
                        label: currentStateData.new_campaign_data.campaign_retailer_company,
                        id: currentStateData.new_campaign_data.campaign_retailer_id,
                      }
                    : ""
                }
                selectionCheck={(option, value) => option?.id === value?.id}
                onChangeCall={(selectedOption) => {
                  handleFieldChange({
                    campaign_retailer_company: selectedOption.label,
                    campaign_retailer_company_id: selectedOption.id,
                  });
                }}
                fieldError={
                  typeof fieldErrors.retailer_company !== "undefined" && fieldErrors.retailer_company !== ""
                    ? fieldErrors.retailer_company
                    : ""
                }
              /> */}
              {/* -------- brand company ------- */}
              <SingleSelect
                label="Brand Company*"
                enableFullWidth={true}
                elemId={"brandCompanyDropdown"}
                options={
                  typeof currentStateData.brand_list !== "undefined" ? Object.values(currentStateData.brand_list) : []
                }
                selectionCheck={(option, value) => option?.id === value?.id}
                defaultVal={
                  typeof currentStateData.new_campaign_data !== "undefined" &&
                  typeof currentStateData.new_campaign_data.campaign_brand_company !== "undefined"
                    ? {
                        label: currentStateData.new_campaign_data.campaign_brand_company,
                        id: currentStateData.new_campaign_data.campaign_brand_company_id,
                      }
                    : ""
                }
                onChangeCall={(selectedOption) => {
                  if (selectedOption.id === "new") {
                    setShowManualBrandCompany(true);
                    handleFieldChange({
                      manual_brand_company: true,
                    });
                  } else {
                    setShowManualBrandCompany(false);
                    handleFieldChange({
                      campaign_brand_company: selectedOption.label,
                      campaign_brand_company_id: selectedOption.id,
                      manual_brand_company: false,
                    });
                  }
                }}
                fieldError={
                  typeof fieldErrors.brand_company !== "undefined" && fieldErrors.brand_company !== ""
                    ? fieldErrors.brand_company
                    : ""
                }
              />
              {/* ------------- manual brand company name ------------ */}
              {showManualBrandCompany && (
                <TextInputField
                  fieldRequired={true}
                  elemId="brandCompanyName"
                  label="Brand Company Name"
                  fieldName="brand_company_name"
                  defaultVal={
                    typeof currentStateData.new_campaign_data !== "undefined" &&
                    typeof currentStateData.new_campaign_data.campaign_brand_company_name !== "undefined"
                      ? currentStateData.new_campaign_data.campaign_brand_company_name
                      : ""
                  }
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    e.preventDefault();
                    handleFieldChange({
                      campaign_brand_company_name: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldErrors.campaign_brand_company_name !== "undefined" &&
                    fieldErrors.campaign_brand_company_name !== ""
                      ? fieldErrors.campaign_brand_company_name
                      : ""
                  }
                />
              )}
            </Stack>
            <Box height={40} />
            {usedFor === "update" && (
              <StyledDarkRedInformationTypography variant="body2">
                Last Modified:
                <StyledInformationSpan
                  style={{
                    color: "#2D2D37",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                >
                  {campaignRecord.last_edit}
                </StyledInformationSpan>
              </StyledDarkRedInformationTypography>
            )}

            {/* ----------- campaign status >> only available for admin user ------- */}
            {/* {usedFor === "update" &&
              checkRole.user_role.toLowerCase() === "admin" &&
              [2, 4].includes(campaignRecord.campaign_status_code) && (
                <>
                  <Box height={40} />
                  <Stack direction={"row"} spacing={4}>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={
                            typeof currentStateData.new_campaign_data !== "undefined" &&
                            typeof currentStateData.new_campaign_data.report_status !== "undefined"
                              ? currentStateData.new_campaign_data.report_status
                              : false
                          }
                          onChange={(e) => {
                            handleFieldChange({
                              report_status: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Report Available"
                    />

                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          onChange={(e) => {
                            handleFieldChange({
                              impression_status: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Impression Available"
                    />
                  </Stack>
                </>
              )} */}
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal(false);
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldErrors({});
              saveCampaignsInformation(usedFor).then((info) => {
                if (info.success === false) {
                  setFieldErrors(info.errorMessages);
                } else {
                  closeModal();
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewCampaign;
